import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import LoadingSpinner from "./LoadingSpinner";
import Swal from "sweetalert2";
import RewardsGrid from "./RewardsGrid";
import axios from "axios";
import Operator from "./Operator";
import * as Ably from "ably";

function HubtelPaymentForm({
  currency,
  currencyOperator,
  countrycode,
  minAmount,
  maxAmount,
  validPrefixes,
  operators,
  operatorValues,
  winuptoAmount,
  platform,
  serviceCode,
  countryName,
  age,
  referralCode,
}) {
  const [details, setDetails] = useState({ phone: "", amount: "" });
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for entire form
  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [errors, setErrors] = useState({
    phone: null,
    amount: null,
    operator: null,
    box: null,
  });

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails(prevDetails => ({ ...prevDetails, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: null }));
  };

  const handleOperatorSelect = (operatorName) => {
    setSelectedOperator(operatorValues[operatorName]);
    setErrors(prevErrors => ({ ...prevErrors, operator: null }));
  };

  const handleBoxClick = (boxNumber) => {
    setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
    setErrors(prevErrors => ({ ...prevErrors, box: null }));
  };

  const handleOtpSubmit = async (otp) => {
    try {
      setLoading(true);
      const verifyUrl = `/smsotp/verify-otp/${otp}/${details.phone}`;
  
      const otpValidationResponse = await axios.get(verifyUrl);
  
      if (otpValidationResponse.status === 200 && otpValidationResponse.data.data.status_code === 200) {
        setLoading(false);
        await Swal.fire({
          html: `
            <div class="flex flex-col items-center">
              <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6 rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
                <img src="megacash.png" alt="MegaCashBox" />
              </div>
              <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">OTP Verified!</h2>
              <p class="text-white text-center text-sm sm:text-base">OTP verified successfully. Proceeding with payment...</p>
            </div>
          `,
          icon: "success",
          showConfirmButton: false,
          customClass: {
            container: "font-sans",
            popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
          },
          timer: 2000,
          willClose: proceedWithPayment,
        });
      } else {
        setLoading(false);
        await Swal.fire({
          title: "Error",
          text: "Invalid OTP. Please try again.",
          icon: "error",
          confirmButtonText: "Retry",
          customClass: {
            container: "font-sans",
            popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
            confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
          }
        });
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setLoading(false);
      await Swal.fire({
        title: "Error",
        text: "An error occurred while verifying the OTP. Please try again later.",
        icon: "error",
        confirmButtonText: "Retry",
        customClass: {
          container: "font-sans",
          popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
          confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
        }
      });
    }
  };

  const sendOtp = async () => {
    try {
      setLoading(true);
      const otpResponse = await axios.post("/smsotp/send-otp", {
        msisdn: `233${details.phone.slice(-9)}`,
      });

      if (otpResponse.status === 200) {
        setLoading(false);
        await Swal.fire({
          html: `
            <div class="flex flex-col items-center">
              <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6  rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
                 <img src="megacash.png"/>
              </div>
              <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">OTP Sent!</h2>
              <p class="mb-4 sm:mb-6 text-white text-center text-sm sm:text-base">A one-time password has been sent to your phone. Please enter it below to proceed:</p>
              <p class="mb-4 sm:mb-6 text-white text-center text-sm sm:text-base">
  Having trouble with OTP delays? Dial <strong>*426*10#</strong> to retrieve it faster.
</p>

            </div>
          `,
          input: "text",
          inputAttributes: {
            placeholder: "Enter OTP",
            'aria-label': "OTP input",
          },
          customClass: {
            container: "font-sans",
            popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
            input: "w-full p-2 sm:p-3 border rounded bg-white text-[#061126] text-base sm:text-lg box-border mx-auto",
            actions: "mt-4 sm:mt-6",
            confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
            cancelButton: "bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
          },
          showCancelButton: true,
          confirmButtonText: "Verify",
          cancelButtonText: "Cancel",
          showLoaderOnConfirm: true,
          preConfirm: handleOtpSubmit,
          allowOutsideClick: () => !Swal.isLoading(),
        });
      } else {
        throw new Error("Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setLoading(false);
      setError(true);
      setErrorMsg("An error occurred while sending the OTP. Please check your phone number and try again.");
    }
  };

  const validateFields = () => {
    const newErrors = {};
    
    // if (!details.phone) {
    //   newErrors.phone = "Phone number is required.";
    // } else {
    //   const enteredPrefix = details.phone.substring(0, 2);
    //   if (!validPrefixes.includes(enteredPrefix)) {
    //     newErrors.phone = `Phone number should start with one of the valid network codes: ${validPrefixes.join(", ")}`;
    //   }
    // }

    if (!details.phone) {
      newErrors.phone = "Phone number is required.";
    } else {
      // Trim whitespace
      const phoneTrimmed = details.phone.trim();
      
      // 1) Digits-only check
      if (!/^\d+$/.test(phoneTrimmed)) {
        newErrors.phone = "Phone number must contain digits only.";
      }
      // 2) Length check (example: 10 digits for Ghana)
      else if (phoneTrimmed.length !== 10) {
        newErrors.phone = "Phone number must be 10 digits long.";
      }
      // 3) Prefix check
      else {
        const enteredPrefix = phoneTrimmed.substring(0, 3);
        
        if (!validPrefixes.includes(enteredPrefix)) {
          newErrors.phone = "Phone number should be valid.";
          //newErrors.phone = `Phone number should be valid. Please use one of the following prefixes: ${validPrefixes.join(", ")}`;
        }
      }
    }




    const betAmount = parseFloat(details.amount);
    if (isNaN(betAmount)) {
      newErrors.amount = "Bet amount is required.";
    } else if (betAmount < minAmount || betAmount > maxAmount) {
      newErrors.amount = `Bet amount should be between ${minAmount} and ${maxAmount} ${currency}.`;
    }

    if (!selectedOperator) {
      newErrors.operator = "Please select a mobile money operator.";
    }

    if (selectedBox === null) {
      newErrors.box = "Please choose a lucky box.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    sendOtp();
  };

  useEffect(() => {
    let timeoutId;

    if (loading) {
      timeoutId = setTimeout(() => {
        setLoading(false);
        Swal.fire({
          html: `
            <div class="flex flex-col items-center">
              <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6 rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
                 <img src="megacash.png"/>
              </div>
              <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">Timeout</h2>
              <p class="text-white text-center text-sm sm:text-base">Payment confirmation took too long. Please try again.</p>
            </div>
          `,
          icon: "error",
          confirmButtonText: "Retry",
          customClass: {
            container: "font-sans",
            popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
            confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
          },
        });
      }, 60000); // 1 minute timeout
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [loading]);

  const proceedWithPayment = async () => {
    try {
      setLoading(true);
      const paymentResponse = await axios.post("/hubtel/webhubtel", {
        betAmount: parseFloat(details.amount),
        phone: details.phone,
        boxNumber: selectedBox,
        currency: currencyOperator,
        correspondent: selectedOperator,
        countrycode: countrycode,
        platform: platform,
        serviceCode: serviceCode,
        countryName: countryName,
        age: age,
        referralCode: referralCode,
      });

      if (paymentResponse.status === 200) {
        const sessionId = paymentResponse.data.sessionId;
        const accountNumber = paymentResponse.data.paymentData.account_number;

        sessionStorage.setItem('hubtelSessionId', sessionId);
        sessionStorage.setItem('hubtelAccountNumber', accountNumber);

        await Swal.fire({
          html: `
            <div class="flex flex-col items-center">
              <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6 rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
                 <img src="megacash.png"/>
              </div>
              <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">SUCCESS!</h2>
              <p class="text-white text-center text-sm sm:text-base">Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: <strong>${sessionId}</strong></p>
            </div>
          `,
          icon: "success",
          customClass: {
            container: "font-sans",
            popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
            confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
          },
        });
      } else {
        throw new Error("Payment failed. Please try again later.");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      await Swal.fire({
        html: `
          <div class="flex flex-col items-center">
            <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6 rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
               <img src="megacash.png"/>
            </div>
            <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">Error</h2>
            <p class="text-white text-center text-sm sm:text-base">An error occurred while processing the payment. Please try again later.</p>
          </div>
        `,
        icon: "error",
        confirmButtonText: "Retry",
        customClass: {
          container: "font-sans",
          popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
          confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
        },
      });
      setLoading(false);
    }
  };

  
  const handleAblyMessage = useCallback((message) => {
    const { referenceId, status, amount, accountNumber,switchProvider } = message.data;
    setLoading(false);

    const storedSessionId = sessionStorage.getItem("hubtelSessionId");
    const storedAccountNumber = sessionStorage.getItem("hubtelAccountNumber");

    if (referenceId !== storedSessionId || storedAccountNumber !== accountNumber) {
      return;
    }

    sessionStorage.removeItem("hubtelSessionId");
    sessionStorage.removeItem("hubtelAccountNumber");

    if (status === "success") {
      // Show success message, but no need to wait for it to close or press a button
      Swal.fire({
        html: `
          <div class="flex flex-col items-center">
            <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6 rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
               <img src="megacash.png"/>
            </div>
            <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">Payment Completed!</h2>
            <p class="text-white text-center text-sm sm:text-base">Your payment of <strong>${amount} ${currency}</strong> was successful! Starting the game...</p>
            <button class="swal2-confirm bg-[#E14400] text-white font-bold py-2 px-4 rounded-lg mt-4">Start Game</button>
          </div>
        `,
        showConfirmButton: false, // Hides the default confirm button
        icon: "success",
        timer: 6000, // Optional timer for the modal, but not necessary for game start
        customClass: {
          container: "font-sans",
          popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
        },
      });

      

      // Immediately trigger the game without waiting for modal interaction
      playGame(amount, selectedBox, accountNumber, referenceId,switchProvider);

    } else if (status === "failed") {
      Swal.fire({
        html: `
          <div class="flex flex-col items-center">
            <div class="w-16 h-16 sm:w-20 sm:h-20 mb-4 sm:mb-6 rounded-full flex items-center justify-center text-white text-xl sm:text-2xl font-bold">
               <img src="megacash.png"/>
            </div>
            <h2 class="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-white text-center">Payment Failed</h2>
            <p class="text-white text-center text-sm sm:text-base">Your payment of <strong>${amount} ${currency}</strong> failed. Please try again.</p>
          </div>
        `,
        icon: "error",
        confirmButtonText: "Retry",
        customClass: {
          container: "font-sans",
          popup: "rounded-lg shadow-xl bg-[#061126] p-4 sm:p-8 max-w-xs sm:max-w-sm md:max-w-md mx-auto",
          confirmButton: "bg-[#E14400] hover:bg-[#FF0000] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full",
        },
      });
    }
  }, [currency, selectedBox]);



  useEffect(() => {
    let ably;
    let channel;

    const setupAbly = async () => {
      ably = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API });
      await ably.connection.once("connected");
      channel = ably.channels.get("deposits");
      channel.subscribe("deposit-success", handleAblyMessage);
      channel.subscribe("deposit-failed", handleAblyMessage);
    };

    setupAbly();

    return () => {
      if (channel) {
        channel.unsubscribe("deposit-success", handleAblyMessage);
        channel.unsubscribe("deposit-failed", handleAblyMessage);
      }
      if (ably) ably.close();
    };
  }, [handleAblyMessage]);

  const playGame = useCallback(async (amount, boxNumber, phone, referenceId,switchProvider) => {
    //console.log(`Attempting to play game: ${referenceId}`);
    //console.log("phone in play game ", phone);

    const formattedPhone = `233${phone.substring(phone.length - 9)}`;

    const gameData = {
      betAmount: amount,
      userBox: boxNumber,
      phoneNumber: formattedPhone,
      sessionId: referenceId,
      currencyOperator,
      correspondent: switchProvider  || selectedOperator,
      currency,
      countrycode,
      serviceCode
    };

    //console.log("Sending data to backend:", gameData);
    setLoading(true);  // Start the loading spinner

    try {
      const response = await axios.post("/webgamehubtel/play-lucky-box-hubtel", gameData);
      const gameResult = response.data;
      //console.log("Game result from backend:", gameResult);

      showGameResultModal(gameResult);
    } catch (error) {
      console.error("Error playing the game:", error);
      showErrorModal();
    } finally {
      setLoading(false);  // Stop the loading spinner once the results are ready
    }
  }, [currency, currencyOperator, countrycode, selectedOperator]);

  const showGameResultModal = (gameResult) => {
    Swal.fire({
      html: `
        <div class="flex flex-col items-center w-full">
          <div class="w-20 h-20 sm:w-24 sm:h-24 mb-4 sm:mb-6 rounded-full flex items-center justify-center">
             <img src="megacash.png" alt="Megacash Logo" class="w-full h-full object-contain" />
          </div>
          <h2 class="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 sm:mb-4 text-white text-center">${gameResult.result}</h2>
          <div class="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4 mb-4">
            <p class="text-white text-center text-sm sm:text-base md:text-lg">You selected Box ${gameResult.userBox}</p>
            <p class="text-white text-center text-sm sm:text-base md:text-lg">Your prize: ${gameResult.prize}</p>
          </div>
          <div id="rewards-grid" class="w-full max-w-3xl mx-auto mt-4"></div>
          <button id="playAgainBtn" class="mt-6 bg-[#E14400] hover:bg-[#FF5500] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg">Play Again</button>
        </div>
      `,
      width: '80%',
      maxWidth: '800px',
      customClass: {
        container: 'font-sans',
        popup: 'rounded-lg shadow-xl bg-[#061126] p-4 sm:p-6 md:p-8',
      },
      showConfirmButton: false,
      showCloseButton: true,
      didOpen: () => {
        const rewardsContainer = Swal.getHtmlContainer().querySelector('#rewards-grid');
        if (rewardsContainer) {
          ReactDOM.createRoot(rewardsContainer).render(
            <RewardsGrid rewards={gameResult.details.LostfakeRewards} userBox={gameResult.userBox} />
          );
        }

        const playAgainBtn = Swal.getHtmlContainer().querySelector('#playAgainBtn');
        playAgainBtn.addEventListener('click', () => {
          Swal.close();
          // Add any additional logic here to reset the game state if needed
        });
      }
    });
  };

  const showErrorModal = () => {
    Swal.fire({
      html: `
        <div class="flex flex-col items-center w-full">
          <div class="w-20 h-20 sm:w-24 sm:h-24 mb-4 sm:mb-6 rounded-full flex items-center justify-center">
             <img src="megacash.png" alt="Megacash Logo" class="w-full h-full object-contain" />
          </div>
          <h2 class="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 sm:mb-4 text-white text-center">Error</h2>
          <p class="text-white text-center text-sm sm:text-base md:text-lg mb-6">There was an issue playing the game. Please try again.</p>
          <button id="retryBtn" class="bg-[#E14400] hover:bg-[#FF5500] text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded text-base sm:text-lg w-full max-w-xs mx-auto">Retry</button>
        </div>
      `,
      width: '80%',
      maxWidth: '600px',
      customClass: {
        container: 'font-sans',
        popup: 'rounded-lg shadow-xl bg-[#061126] p-4 sm:p-6 md:p-8',
      },
      showConfirmButton: false,
      showCloseButton: true,
      didOpen: () => {
        const retryBtn = Swal.getHtmlContainer().querySelector('#retryBtn');
        retryBtn.addEventListener('click', () => {
          Swal.close();
          // Add any additional logic here to retry the game
        });
      }
    });
  };

  const customGradient = {
    background: 'linear-gradient(to bottom right, #E14400, #FF0000)'
  };

  const boxColors = [
    'linear-gradient(to bottom right, #73BF1E, #36590E)', // green gradient
    'linear-gradient(to bottom right, #C3391E, #5D1B0E)', // orange gradient
    'linear-gradient(to bottom right, #04296F, #084FD4)', // blue gradient
    'linear-gradient(to bottom right, #4E0092, #17002C)', // purple gradient
    'linear-gradient(to bottom right, #E14400, #7B2500)', // bright green gradient
    'linear-gradient(to bottom right, #A70000, #410000)', // red gradient
  ];

  const gridStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    marginTop: "20px",
    marginBottom: "20px",
  };

  const boxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    aspectRatio: "1 / 1", 
    border: "1px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
  };

  const boxNumberStyle = {
    fontSize: "1.5em",
    color: "white",
    fontWeight: "bold",
    textShadow: "1px 1px 2px rgba(0,0,0,0.7)",
  };

  return (
    <div className="text-center text-white lg:w-1/2 px-2 md:px-10">
      <div className="mb-6 border-2 border-gray-900 rounded-lg bg-[#061126]">
        <h1 className="text-md font-bold uppercase">Mega cash lucky box</h1>
        <div className="text-yellow-400 font-bold tracking-wider text-xl">
          Win up to {currency} {winuptoAmount} 
        </div>
      </div>

      {error && (
        <div className="bg-red-500 text-white font-bold p-3 mb-4 rounded-md text-center" role="alert">
          {errorMsg}
        </div>
      )}

      {loading ? (
           <div className="flex flex-col items-center justify-center min-h-screen">
           <div className="flex flex-col items-center space-y-4">

           <img
            src="megacash.png"
            alt="MegaCash Logo"
            className="h-10 w-10 mr-1"
            />
             <LoadingSpinner className="w-16 h-16 text-blue-500 animate-spin" />
             <h3 className="text-white text-lg font-semibold">Processing your request...</h3>
           </div>
         </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="bg-[#061126] border-2 border-gray-900 mb-6 p-6 rounded-lg">
            <div className="mb-3">
              <label htmlFor="phoneInput" className="mb-2">Enter your phone number</label>
              <input
                type="tel"
                className={`w-3/4 bg-[#1a285550] border-2 ${
                  errors.phone ? 'border-red-500' : 'border-[#7b7b7c71]'
                } rounded-md p-3 text-white placeholder-[#1e6cff] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-center`}
                id="phoneInput"
                required
                autoFocus
                name="phone"
                onChange={handleChange}
                value={details.phone}
                placeholder="e.g., 0241234567"
                //placeholder={`e.g., ${validPrefixes.map((prefix) => prefix + "xx").join(" or ")}`}
              />
              {errors.phone && <div className="text-red-500 mt-2">{errors.phone}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="amountInput" className="form-label">Enter an amount to play</label>
              <input
                type="number"
                className={`w-3/4 bg-[#1a285550] border-2 ${
                  errors.amount ? 'border-red-500' : 'border-[#7b7b7c71]'
                } rounded-md p-3 text-white placeholder-[#1e6cff] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-center`}
                id="amountInput"
                required
                name="amount"
                onChange={handleChange}
                value={details.amount}
                //placeholder={`e.g., ${currency} ${minAmount} - ${currency} ${maxAmount}`}
                placeholder={`Play GHC 5 or more`}
              />
              {errors.amount && <div className="text-red-500 mt-2">{errors.amount}</div>}
            </div>

            <div className="text-center pt-8 mb-6">
              <label htmlFor="operatorInput" className="text-white md:text-xl font-semibold">Choose Your Mobile Money Operator</label>
            </div>
            <div className="flex justify-center md:space-x-8">
              {operators.map((operator) => (
                <Operator
                  key={operator.id}
                  logo={operator.logo}
                  name={operator.name}
                  onClick={() => handleOperatorSelect(operator.id)}
                  selected={selectedOperator === operatorValues[operator.id]}
                  className="bg-white rounded-lg p-4 w-28 h-28 justify-center"
                />
              ))}
            </div>
            {errors.operator && <div className="text-red-500 mt-2">{errors.operator}</div>}
          </div>

          <div className="bg-[#061126] border-2 border-gray-900 p-6 rounded-lg">
            <label htmlFor="boxnumberInput" className="text-white text-xl mb-4 block text-center">
              Choose Your Lucky Box
            </label>
            <div className="grid grid-cols-3 gap-4 md:grid-cols-3 lg:grid-cols-3 lg:px-10">
              {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
                <div
                  key={boxNumber}
                  className="flex justify-center items-center aspect-square border-1 lg:w-36 rounded-lg cursor-pointer"
                  style={{
                    background: boxColors[index],
                    boxShadow: selectedBox === boxNumber ? "0px 0px 5px 3px #fff" : "none",
                  }}
                  onClick={() => handleBoxClick(boxNumber)}
                >
                  <span style={boxNumberStyle}>Box {boxNumber}</span>
                </div>
              ))}
            </div>
            {errors.box && <div className="text-red-500 mt-2">{errors.box}</div>}

            <div className="mt-6">
              <button
                style={customGradient}
                type="submit"
                className="w-full text-white py-3 rounded-md font-bold text-lg"
              >
                PLAY {currency} {details.amount} 
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default HubtelPaymentForm;

